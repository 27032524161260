import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import {ChangePassword} from "./screens/changepassword/ChangePassword";
import { CandidateQuery } from './screens/reports/CandidateQuery';
import { ModuleExport } from './screens/reports/ModuleExport';


export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,   
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/candidate-queries" render={(props) => <CandidateQuery rptTtile="Candidate Query" rpt="HRC" {...props} />} />,
    <Route exact path="/repoexport" render={(props) => <ModuleExport rptTtile="Module Export" rpt="ModuleExport" {...props} />} />,
    
    
];
