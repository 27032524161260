import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    BooleanInput,required,NumberInput,AutocompleteInput
} from 'react-admin';
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
export const JobTitleCreate = (props) => {
    const classes = useStyles({});
    // Initialize initial form values
    const initialValues = {
        isActive: true,
        isVisible: true
    };

    // Handle form submission
    const handleSubmit =async (formData) => {
                const date = formData.date ? moment(formData.date).format() : null;
                    let m = moment();
                    const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;
                    const data = {
                        candidateId: parseInt(props.candidateId), 
                        jobTitleId:parseInt(formData.jobTitleId),  
                        createdBy: userId,
                        createdDate: m.toISOString(),
                        modifyBy: userId,
                        modifyDate: m.toISOString(),
                    };  
                    apiClient.addEntiyRecord("candidates-job-titles", data)
                        .then(res => {
                            console.log(res);
                            props.onClose();
                        })
                        .catch(err => {
                            console.error(err);
                        }); 
    };
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });

    return (
        <Create {...props}>
            <SimpleForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined"
            > 
                 <ReferenceInput
                    label="Job Title"
                    source="jobTitleId"
                    reference="job-titles"
                    validate={required()}
                    sort={{ field: 'name', order: 'asc' }}
                    fullWidth={true}
                >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>
                
                
            </SimpleForm>
        </Create>
    );
};
