import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { Button } from '@material-ui/core';
import * as apiClient from "../../common/apiClient";
export const AddressFields = ({ formData,
    countryField,
    stateField,
    cityField,
    countryLabel,
    stateLabel,
    cityLabel,
    showAsAbove
    , ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    const handleChange=async(id)=>{
        await apiClient.getdata("zip-codes/"+id).then(res => {
            form.change('cityId',res.cityId)
            form.change('stateId',res.stateId)
            form.change('countryId',res.countryId)
        }).catch(err => {
            
            
        });
    }

    return (
        <div className="address-field-parent"> 
        <ReferenceInput perPage={25} 
                 variant="outlined" 
                 validate={[required()]} 
                 filterToQuery={searchText => ({ 'name~like': searchText })}  
                 sort={{ field: 'name', order: 'ASC' }} 
                label="Country" source="countryId" reference="countries" fullWidth={true}  formClassName={classes.last_inline_input}
                onChange={(v) => {                    
                    form.change("stateId", 0);
                    form.change("cityId", 0);
                    form.change("zipCodeId", 0);
                    
                }}
                 >
                    
                <AutocompleteInput  variant="outlined" optionText="name" />
            </ReferenceInput>
            { formData.countryId>0 && <ReferenceInput perPage={25} 
                 variant="outlined" 
                 validate={[required()]} 
                 filter={{ countryId: formData.countryId }}
                 sort={{ field: 'code', order: 'ASC' }} 
                 filterToQuery={searchText => ({ 'code~like': searchText })}  
                label="State" source="stateId" reference="states" fullWidth={true}  formClassName={classes.first_inline_input} 
                onChange={(v) => {                    
                    
                    form.change("cityId", 0);
                    form.change("zipCodeId", 0);
                    
                }}
                >
                <AutocompleteInput  variant="outlined" optionText="code" />
            </ReferenceInput> }
            {formData.stateId>0 && <ReferenceInput perPage={25} 
                 variant="outlined" 
                 validate={[required()]} 
                 filter={{ stateId: formData.stateId }}
                 filterToQuery={searchText => ({ 'name~like': searchText })} 
                 sort={{ field: 'name', order: 'ASC' }}  
                label="City" source="cityId" reference="cities" fullWidth={true}  formClassName={classes.last_inline_input} 
                onChange={(v) => {                    
                    
                    
                    form.change("zipCodeId", 0);
                    
                }}
                >
                <AutocompleteInput  variant="outlined" optionText="name" />
            </ReferenceInput>}
            {formData.cityId>0 && <ReferenceInput perPage={25} sort={{ field: 'zipCode', order: 'ASC' }}
                filterToQuery={searchText => ({ 'zipCode~like': searchText })}  variant="outlined"
                filter={{  cityId: formData.cityId }}
                
                label="Zipcode" source="zipCodeId" reference="zip-codes" fullWidth={true}  formClassName={classes.first_inline_input}
                >
                <AutocompleteInput  variant="outlined" optionText="zipCode" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>}
            

            
            </div>
        
    )
}
