import React, { useState, useEffect, useCallback } from 'react';
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField, FileInput, FileField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, AutocompleteInput, Show
} from 'react-admin';
import { AddressFields } from "./AddressFields";
import Button from '@material-ui/core/Button';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';


const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: '50%',
    },
}));
export const CandidateView = props => {



    const [isAddExperience, setIsAddExperience] = useState(false);
    const [isEditExperience, setIsEditExperience] = useState(false);

    const [isAddJobTitle, setIsAddJobTitle] = useState(false);
    const [isEditJobTitle, setIsEditJobTitle] = useState(false);

    const [isaddDocuments, setIsAddDocuments] = useState(false);
    const [isAddQualification, setIsAddQualification] = useState(false);
    const [isEditQualification, setIsEditQualification] = useState(false);


    const [isAddSkill, setIsAddSkill] = useState(false);
    const [isEditSkill, setIsEditSkill] = useState(false);

    const [isEditEmployment, setIsEditEmployment] = useState(false);


    const [IsEditRecord, setIsEditRecord] = useState(null);
    const myclasses = myStyles({});
    const classes = useStyles({});
    const refresh = useRefresh();
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });

    const DisplayFileLink = (props) => {
        let { record } = props;

        if (record.docUrl && record.docUrl.length > 0)
            return (
                <a href={record.docUrl}>View File</a>
            )
        else {
            return null
        }
    }
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>Edit</Button>
    }
    const onCloseForm = () => {
        setIsAddExperience(false)
        setIsEditExperience(false)
        setIsAddJobTitle(false)
        setIsEditJobTitle(false)
        setIsAddQualification(false)
        setIsEditQualification(false)
        setIsAddSkill(false)
        setIsEditSkill(false)
        setIsEditEmployment(false);
        setIsAddDocuments(false);

        refresh();
    }
    const onCancel = () => {
        setIsAddExperience(false)
        setIsEditExperience(false)
        setIsAddJobTitle(false)
        setIsEditJobTitle(false)
        setIsAddQualification(false)
        setIsEditQualification(false)
        setIsAddSkill(false)
        setIsEditSkill(false)
        setIsEditEmployment(false);

    }
    let defaultValues = {
        countryId: 0,
        stateId: 0,
        cityId: 0
    };
    return (
        <>
            <React.Fragment>
                <div style={{ overflowY: "Scroll", padding: 10, marginBottom: 100 }} >
                    <h4>Candidate View</h4>

                    <Show id={props.record} title="View Candidate  " basePath="candidates" resource="candidates" undoable={false} initialValues={defaultValues}>
                        <TabbedForm variant="outlined" toolbar={null} >
                            <FormTab value="DETAILS" label="Detail">

                                <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
                                <TextInput source="name" fullWidth={true} label="Name" validate={[required()]} formClassName={classes.last_inline_input} />


                                <TextInput source="address1" label="Address Line 1" fullWidth={true} multiline formClassName={classes.first_inline_input} />
                                <TextInput source="address2" label="Address Line 2" fullWidth={true} multiline formClassName={classes.last_inline_input} />
                                <FormDataConsumer fullWidth={true}>
                                    {formDataProps => (
                                        <AddressFields {...formDataProps}

                                            showAsAbove={true}
                                        />
                                    )}
                                </FormDataConsumer>
                                <TextInput source="personnelEmailId" label="Personal Email " fullWidth={true} validate={[required(), email()]} formClassName={classes.one_three_input} />
                                <TextInput source="alternateEmailId" label="Alternate Email " fullWidth={true} validate={[required(), email()]} formClassName={classes.two_three_input} />
                                <TextInput source="contactNo" label="Contact No " fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

                                <TextInput source="alternateContactNo" label="Alternate Contact No" fullWidth={true} formClassName={classes.one_three_input} />
                                <TextInput source="ssnno" label="SSNNO " fullWidth={true} formClassName={classes.two_three_input} />
                                <div></div>


                                <ReferenceInput perPage={25} variant="outlined" label="Highest Qualification " source="highestQualificationId" reference="qualifications" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>


                                {/* <ReferenceInput perPage={25} variant="outlined" label="Current Location State " source="currentLocationStateId" reference="states" fullWidth={true} formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput> */}
                                <div formClassName={classes.two_three_input}>

                                </div>

                                <BooleanInput source="relocation" label="Relocation" fullWidth={true} formClassName={classes.last_three_input} />


                                <ReferenceInput perPage={25} variant="outlined" label="Work Authorization" source="workauthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.one_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>

                                <ReferenceInput perPage={25} variant="outlined" label="Job Title  " source="preferredJobTitleId" reference="job-titles" fullWidth={true} formClassName={classes.two_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>

                                <FormDataConsumer fullWidth={true} formClassName={classes.last_three_input}>
                                    {({ formData, ...rest }) =>

                                        (formData.workauthorizationId && formData.workauthorizationId === 4) ? (
                                            <ReferenceInput perPage={25} variant="outlined" label="Current Employment " source="currentEmploymentId"
                                                reference="clients"
                                                fullWidth={true} formClassName={classes.last_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                                            </ReferenceInput>
                                        ) : (
                                            <ReferenceInput perPage={25} variant="outlined" label="Current Employment" source="currentEmploymentId"
                                                reference="clients"
                                                fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                                            </ReferenceInput>
                                        )
                                    }
                                </FormDataConsumer>

                                <NumberInput source="salaryinUsd" label="Salary/Rate " fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
                                <ReferenceInput perPage={25} variant="outlined" label="Hourly Rate" source="hourlyRateId"
                                    reference="lookups"

                                    sort={{ field: 'name', order: 'asc' }}
                                    filter={{ type: 'HRLRT' }}

                                    fullWidth={true} formClassName={classes.two_three_input}       >
                                    <SelectInput optionText="name" />
                                </ReferenceInput>

                                <ReferenceInput perPage={25} variant="outlined" label="Work Setting" source="workSettingId"
                                    reference="lookups"

                                    sort={{ field: 'name', order: 'asc' }}
                                    filter={{ type: 'WKSET' }}

                                    fullWidth={true} formClassName={classes.last_three_input}      >
                                    <SelectInput optionText="name" />
                                </ReferenceInput>



                                <ReferenceInput perPage={25} variant="outlined" label="Work Experience" source="workExperienceId" reference="experiences" fullWidth={true} formClassName={classes.one_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>

                                <ReferenceInput perPage={25} variant="outlined" label="Top Skill" source="topSkillId" reference="skills" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>

                                <ReferenceInput perPage={25} variant="outlined" label="Preferred Job Title" source="secondarySkillId" reference="skills" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                                </ReferenceInput>



                                <BooleanInput source="isSignedPaper" label="Signed Paper" fullWidth={true} formClassName={classes.one_three_input} />

                                <ReferenceInput label="Status" source="statusId" fullWidth={true} reference="lookups" filter={{ type: 'EMPLOYEEROLE' }} formClassName={classes.two_three_input}>
                                    <SelectInput optionText="name" validate={[required()]} />
                                </ReferenceInput>

                                <div>

                                </div>

                                <FileField source="resumeUrl" label="" title="Download Resume" formClassName={classes.first_inline_input} />


                                <FileField source="covernoteUrl" label="" title="Download Cover Note" formClassName={classes.last_inline_input} />

                                <TextInput source="remarks" label="Remarks" fullWidth={true} multiline formClassName={classes.one_three_input} />
                                <TextInput source="dlStateId" label="DL/State ID" fullWidth={true} formClassName={classes.two_three_input} />
                                <TextInput source="linkedInId" label="LinkedIn Id" fullWidth={true} formClassName={classes.last_three_input} />
                                <div style={{ marginTop: 10 }}  >
                                    <h4>Documents</h4>
                                </div>
                                <ReferenceManyField fullWidth={true}
                                    addLabel={false}
                                    sort={{ field: 'id', order: 'ASC' }}
                                    reference="candidate-docs"
                                    target="candidateId">
                                    <Datagrid>


                                        <DateField showTime={true} label="createdDate" source="createdDate" locales="fr-FR" />
                                        <ReferenceField source="typeId" reference="lookups" label="Type" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <TextField label="Remarks" source="remarks" />
                                        <DisplayFileLink label="Document URL" source="docUrl" />

                                    </Datagrid>
                                </ReferenceManyField>

                                <div style={{ marginTop: 10 }}  >
                                    <h4>Job Titles</h4>
                                </div>
                                <ReferenceManyField perPage={100} reference="candidates-job-titles" target="candidateId" addLabel={false} fullWidth={true}>
                                    <Datagrid rowClick={false}>

                                        <ReferenceField source="jobTitleId" reference="job-titles" label="Job Titles" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                    </Datagrid>
                                </ReferenceManyField>

                                <div style={{ marginTop: 10 }}  >
                                    <h4>Qualifications</h4>
                                </div>
                                <ReferenceManyField perPage={100} reference="candidates-qualifications" target="candidateId" addLabel={false} fullWidth={true}>
                                    <Datagrid rowClick={false}>


                                        <ReferenceField source="qualificationId" reference="qualifications" label="Qualification" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                    </Datagrid>
                                </ReferenceManyField>

                                <div style={{ marginTop: 10 }}  >
                                    <h4>Skills</h4>
                                </div>
                                <ReferenceManyField perPage={100} reference="candidates-skills" target="candidateId" addLabel={false} fullWidth={true}>
                                    <Datagrid rowClick={false}>


                                        <ReferenceField source="skillId" reference="skills" label="Skill" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>

                                        <ReferenceField source="experienceId" reference="experiences" label="Experience" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>

                                        <TextField source="lastUsedYears" label="Last Used Year" />
                                        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                    </Datagrid>
                                </ReferenceManyField>


                                <div style={{ marginTop: 10 }}  >
                                    <h4>Employment</h4>

                                </div>

                                <ReferenceManyField perPage={100} reference="candidates-client-maps" target="candidateId" addLabel={false} fullWidth={true}>
                                    <Datagrid rowClick={false}>
                                        {/* <CDeleteButton {...props} /> */}

                                        <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
                                            <TextField source="name" />
                                        </ReferenceField>


                                        <ReferenceField source="agentPoId" reference="agent-pos" label="PO Number" link={false}>
                                            <TextField source="pono" />
                                        </ReferenceField> 

                                        <TextField source="empCode" label="Emp Code" />
                                        <DisplayDateTime source="startDate" label="Start Date" locales="fr-FR" />
                                        <DisplayDateTime source="endDate" label="End Date" locales="fr-FR" />
                                        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                            <TextField source="userName" />
                                        </ReferenceField>
                                        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                    </Datagrid>
                                </ReferenceManyField>
                            </FormTab>




                        </TabbedForm>
                    </Show>
                </div>
            </React.Fragment>

        </>
    );
}