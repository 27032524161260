import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { Button } from '@material-ui/core';
import * as apiClient from "../../common/apiClient";
export const AddressFields = ({ formData,
    countryField,
    stateField,
    cityField,
    countryLabel,
    stateLabel,
    cityLabel,
    showAsAbove
    , ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    const handleChange=async(id)=>{
        // // await apiClient.getdata("zip-codes/"+id).then(res => {
        // //     form.change('cityId',res.cityId)
        // //     form.change('stateId',res.stateId)
        // //     form.change('countryId',res.countryId)
        // // }).catch(err => {
            
            
        // });
    }
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
      });
    return (
        <div > 
            <ReferenceInput perPage={25} 
                 variant="outlined" 
                label="Country" source="countryId" reference="countries" fullWidth={true} 
                onChange={()=>{ 
                     
                    form.change('stateId',null)
                    }}
                    validate={[required()]}       sort={{ field: 'name', order: 'ASC' }}
                >
                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                    filterToQuery={filterToQuery}
                    emptyValue={''}
                    />
            </ReferenceInput> 
            <ReferenceInput perPage={25} 
            
                 variant="outlined"   filter={{ countryId: formData.countryId?formData.countryId:0 }}
                label="State" source="stateId" reference="states" fullWidth={true}       sort={{ field: 'name', order: 'ASC' }}
                 
                    validate={[required()]}
                 >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                    filterToQuery={filterToQuery}
                    emptyValue={''}
                    />
            </ReferenceInput>

           
            
            </div>
        
    )
}
