import React, { useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../formStyles";
import moment from "moment";

export const CandidateProgress = props => {
    const classes = useStyles({});
    const [data, setData] = useState(null);

    const initialValues = {
        isActive: true
    }


    const handleChange = (id) => {
        setData(null)
        apiClient.getCandidateProgress(id).then(res => {
            setData(res[0].progresshtml)

        }).catch(err => {
            console.log(err);
        });
    }


    const handleSubmit = (formdata) => {
        //console.log(formdata);

    }
    return (
        <>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<null />}
                variant="outlined">
                <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} variant="outlined" filterToQuery={searchText => ({ 'name~like': searchText })} validate={[required()]} label="Candidate" source="candidateId" reference="candidates" fullWidth={true}
                    onChange={(val) => handleChange(val)}
                >
                    <AutocompleteInput
                        optionText="name"
                    />
                </ReferenceInput>
                {data && <div  style={{border:1}}dangerouslySetInnerHTML={{ __html: data }}>

                </div>}

            </SimpleForm>
        </>

    );
}
