import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, FormDataConsumer,useNotify
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";


const StageEdit = props => {
    const classes = useStyles({});
    const notify = useNotify();
    const [statues, setStatues] = useState(null);
    const [initialValues, setSelectRecord] = useState({
        
    }); 

   
    const handleSubmit = (formdata) => {
         
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }

        let data={...formdata};
        let m = moment(new Date());
        
        data. modifyDate=  m.toISOString()
        data. modifyBy=  userId
        apiClient.updateRecord("requirements-stages", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    useEffect(() => {
        loadStatus();
    }, []);
    const loadStatus = () => {
        apiClient.getdata_v1 ("lookups?filter[where][type]=PLCH").then(res => {
            setStatues(res);
        })
    }
  
    return (
        <div style={{overflow:"scroll",marginBottom:"20px"}}>
        <Edit {...props} id={props.record.id} resource="requirements-stages" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} showCancel={true} />}
                variant="outlined">
                       <ReferenceInput label="Selection Stage" fullWidth={true}
                    perPage={5000} source="selectionStageId"
                    
                    reference="selection-stages"
                    formClassName={classes.one_three_input}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput  optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                <ReferenceInput label="Selection Sequence" fullWidth={true}
                    perPage={5000} source="sequenceId" 
                    reference="lookups"
                    formClassName={classes.two_three_input}
                    filter={{ type: 'SEQ'}}
                    
                    sort={{ field: 'name', order: 'ASC' }} 

                >
                    <SelectInput  optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>         

                
                <div style={{width:"100%"}} ><h4>System Fields for mail content:-</h4></div>
                <div style={{width:"100%"}} >
                {statues!=null && statues[0]?.name}
                </div>   
                <RichTextInput source="candidateMailContents" label="Candidate Mail Contents" fullWidth={true} formClassName={classes.first_inline_input}  />
                <RichTextInput source="returnMailContents" label="Return Mail Contents" fullWidth={true} formClassName={classes.last_inline_input}   />
                <RichTextInput source="internalMailContents" label="Internal Mail Contents" />
                
                <TextInput source="remarks"  label="Remarks" fullWidth={true} multiline  />
                <BooleanInput source="isMailTrigger" label="Mail Trigger" fullWidth={true} initialValue={false}  formClassName={classes.one_4_input}    />
                {/* <BooleanInput source="isTestLink" label="Share Test Link" fullWidth={true} initialValue={false} formClassName={classes.two_4_input}/>
                 */}
                <BooleanInput initialValue={true} source="isApplicableForCandidate" label="Is Applicable For Candidate"  fullwidth={true} formClassName={classes.three_4_input} />
                {/* <BooleanInput initialValue={true} source="isApplicableForEmployee" label="Is Applicable For Freelancer"  fullwidth={true} formClassName={classes.last_4_input} />
                <BooleanInput initialValue={true} source="isAssignedToEvaluator" label="Assign Evaluator"  fullwidth={true} /> */}
                
                
                

            </SimpleForm>
        </Edit>
        </div>
    );
}
export default StageEdit;