import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function ActionLogs({ actionLogs, onSelectTest }) {
    const classes = useStyles();
    return (
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Stage</StyledTableCell>
                    <StyledTableCell >Action Date</StyledTableCell>
                    <StyledTableCell >Response Date</StyledTableCell>
                    
                    <StyledTableCell >Status</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                    
                    
                    <StyledTableCell>Created By</StyledTableCell>
                    <StyledTableCell>Created Date</StyledTableCell>
                    
                </TableRow>
            </TableHead>
         
            <TableBody>
                {actionLogs.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>{row["Stage"]}</StyledTableCell>
                        <StyledTableCell>{row["Action Date"]}</StyledTableCell>
                        <StyledTableCell>{row["Response Date"]}</StyledTableCell> 
                        <StyledTableCell>{row["Status"]}</StyledTableCell>
                        <StyledTableCell>{row["Remarks"]}</StyledTableCell> 
                        <StyledTableCell>{row["Created By"]}</StyledTableCell>
                        <StyledTableCell>{row["Created Date"]}</StyledTableCell> 
                        
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}
