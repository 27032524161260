import React, { useState,useCallback } from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Authorizer } from "../../common/Authorizer";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  TopToolbar,
  useNotify,
  useRefresh,
  AutocompleteInput
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { ImportProfile } from "./ImportProfile";
import * as apiClient from "../../common/apiClient";
import { FileViewer } from "./FileViewer";
import viewtab from "../../images/viewtab.png"
import resume from "../../images/resume.png"
 


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 600,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
export const ActivityList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedRecord, setSelectedRecord] = useState(null);  
  const [isOpenView, setIsOpenView] = useState(false);  
  const [isOpen, setIsOpen] = useState(false);  
  let propsObj = Authorizer(props, "Edit");
  
  const [isApproving, setIsApproving] = useState(false);
  const myclasses = myStyles({});
  propsObj.hasCreate = false;
  const handleClose = () => {
    setIsOpen(false);
    setIsOpenView(false);
    refresh();
  };
  const CustomCheckBox = (props) => {
    let { record } = props;
    let chckbox:any = null;
    if (record.statusId != 63) {
      chckbox = (
        <input
          type="checkbox"
          checked={selectedData.includes(record.id)}
          onChange={(e) => {
            selectRecord(e, record.id);
          }}
        />
      );
    }
    return chckbox;
  };
  
 

  const handleApprove = () => {
    let data = [...selectedData];
    let userId:any;
     userId= localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    setIsApproving(true);
    Promise.all(data.map(id => 
      apiClient.ApproveImportCandidate({ id, userId })
    ))
    .then(responses => {
      notify('All candidates approved successfully', 'info');
      refresh(); 
      setSelectedData([]);
      setIsApproving(false);
    })
    .catch(err => {
      console.log(err);
      notify('There was an error approving candidates', 'warning');
      setIsApproving(false);
    });
  };
  const selectRecord = (e, id) => {
    if (e.target.checked) {
      setSelectedData((prev) => [...prev, id]);
    } else {
      setSelectedData((prev) => prev.filter((id) => id !== id));
    }
  }
  const ViewButton = (props) => (
    <Button style={{ marginTop: 4  ,marginLeft: 4}}  color="primary" variant="outlined" onClick={props.click}  > {props.label} </Button>
  )
  const CustomAction = (props) => {
    
    let { record } = props;
    
      return (
        <div style={{ display: "flex" }}> 
          {propsObj.hasDelete && <CDeleteButton {...props}/>}  
          <img src={resume} height={20} style={{marginTop:10,cursor:"pointer",marginLeft:10}}
    onClick={(e) => {setSelectedRecord(record);setIsOpen(true)}}/>
          
          <img src={viewtab} height={20} style={{marginTop:10,marginLeft:10,cursor:"pointer"}}
    onClick={(e) => { const encodedUrl = encodeURIComponent(record.resumeUrl); 
                      const viewerUrl = `https://docs.google.com/viewer?url=${encodedUrl}`;         
                      setTimeout(() => {
                          window.open(viewerUrl, '_blank');
                      }, 100);
    }}
/>
          
          

          
        </div>
      )
    }
  const Filters = (props) => (
    <Filter {...props} variant="outlined">

     
    <SearchInput
      variant="outlined"
      label=" Name"
      source="name~like"
      alwaysOn
      
    />

<SearchInput
      variant="outlined"
      label=" Email"
      source="personnelEmailId~like"
      
      alwaysOn
    />
 
  
  <SearchInput
      variant="outlined"
      label="Contact No"
      source="contactNo~like"
      alwaysOn
      
    />

<SearchInput
      variant="outlined"
      label="Alternate Email "
      source="alternateEmailId~like" 
    />
<SearchInput
      variant="outlined"
      label="SSNNO "
      source="ssnno~like" 
    />

<SearchInput
      variant="outlined"
      label="Alternate Contact No "
      source="alternateContactNo~like" 
    />
 
  <ReferenceInput perPage={25} variant="outlined" label="Highest Qualification" source="highestQualificationId" reference="qualifications" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput>

  <ReferenceInput perPage={25} variant="outlined" label="Work Authorization" source="workauthorizationId" 
  reference="workauthorizations" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput>

  <ReferenceInput perPage={25} variant="outlined" label="Job Title" source="preferredJobTitleId" 
  reference="job-titles" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput>

  <ReferenceInput perPage={25} variant="outlined" label="Current Employment" source="currentEmploymentId" 
  reference="clients" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput>
  <ReferenceInput perPage={25} variant="outlined" label="Hourly Rate" source="hourlyRateId" 
  reference="lookups" fullWidth={true}  filter={{ type: 'HRLRT' }} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput> 
  <ReferenceInput perPage={25} variant="outlined" label="City" source="cityId"
      reference="cities" fullWidth={true} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
    </ReferenceInput>
  <ReferenceInput perPage={25} variant="outlined" label="Work Setting" source="workSettingId" 
  reference="lookups" fullWidth={true} filter={{ type: 'WKSET' }} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput> 

  <ReferenceInput perPage={25} variant="outlined" label="Work Experience" source="workExperienceId" 
  reference="experiences" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput> 

  <ReferenceInput perPage={25} variant="outlined" label="Top Skill" source="topSkillId" 
  reference="skills" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput> 
                 
  
  <ReferenceInput perPage={25} variant="outlined" label="Preferred Job Title" source="secondarySkillId" 
  reference="skills" fullWidth={true}   sort={{ field: 'name', order: 'ASC' }} allowEmpty >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }}   />
  </ReferenceInput> 
 

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} onChange={() => {setSelectedData([])}}
    filter={{ type: 'IMPSTATUS' }}
      label="Status" source="statusId" reference="lookups" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
    </ReferenceInput>
     
    </Filter>
  );
  const rowStyle = (record, index) => {

    if (record.statusId ==63 ) {
        return { backgroundColor: '#F08080' };
    }
    else {
        return { backgroundColor: 'inherit' };
    }
}
  return (
    <React.Fragment>

      <List sort={{ field: 'id', order: 'DESC' }} {...propsObj}  exporter={false} bulkActionButtons={false}  filters={<Filters />}
        actions={<TopToolbar>
          <Button style={{ margin: 5,height:40 }} onClick={() => setIsOpen(true)} variant="contained" color="primary"
          > Import  </Button>
          <Button style={{ margin: 5,height:40 }} disabled={selectedData.length == 0 || isApproving} onClick={() => handleApprove()} variant="contained" color="primary"
          > Approve  </Button>
        </TopToolbar>}
       
      >
        <Datagrid rowClick={false}   rowStyle={rowStyle}>
          {/* {propsObj.hasShow && !propsObj.hasEdit && <ShowButton />} */}
          

          <CustomCheckBox label="Select" />
          {propsObj.hasShow && propsObj.hasEdit && <EditButton />}
          {/* {propsObj.hasDelete && <CDeleteButton {...props} />} */}
          <CustomAction id="Action"/>
          <TextField source="name" label="Candidate Name " />        

          <ReferenceField  variant="outlined" label="Status" source="statusId"  link={false}
                                reference="lookups"
                                >
                                <TextField source="name" />
                            </ReferenceField>  
                  
       
                            <TextField source="contactNo" label="Contact No " />
          <ReferenceField variant="outlined" label="Job Title " source="preferredJobTitleId" reference="job-titles" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField variant="outlined" label="Work Authorization" source="workauthorizationId" reference="workauthorizations" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField variant="outlined" label="Location" source="cityId"
            reference="cities" link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          
          <ReferenceField variant="outlined" label="Work Experience" source="workExperienceId" reference="experiences" fullWidth={true} link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField variant="outlined" label="Hourly Rate" source="hourlyRateId" link={false}
          
          reference="lookups"

        >
          <TextField source="name" />
        </ReferenceField>


          <TextField source="ssnno" label="SSNNO " />
          <ReferenceField variant="outlined" label="Highest Qualification " source="highestQualificationId" reference="qualifications" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="relocation" label="Relocation" />
         
         
          {/* 4 */}
          <ReferenceField variant="outlined" label="Current Employment" source="currentEmploymentId"
            reference="clients" link={false}
          >
            <TextField source="name" />
          </ReferenceField>

        
          <NumberField source="salaryinUsd" label="Salary/Rate " />
          
          <ReferenceField variant="outlined" label="Work Setting" source="workSettingId" link={false}
            reference="lookups"

          >
            <TextField source="name" />
          </ReferenceField>




          <ReferenceField variant="outlined" label="Top Skill" source="topSkillId" reference="skills"  link={false}>
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField perPage={25} variant="outlined" label="Preferred Job Title" source="secondarySkillId" reference="skills"  link={false}>
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="isSignedPaper" label="Signed Paper" />

          <BooleanField source="isActive" label="Active" />
          <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
          <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
        </Datagrid>
        
      </List>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper1500 }}
        open={isOpen || isOpenView }     >
        {isOpen && <ImportProfile
          onClose={handleClose}
          onCancel={handleClose}
          {...props}
        />}
        {isOpenView && <FileViewer
          onClose={handleClose}
          record={selectedRecord}
          {...props}
        />}

      </Drawer>
    </React.Fragment>

  );
};
