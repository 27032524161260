import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import * as apiClient from "../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField, useNotify,

} from 'react-admin';
import { useStyles } from "../../formStyles";
import { MultipleSelect } from "react-select-material-ui";

export const EmployeeFields = ({ formData, ...rest }) => {
    const notify = useNotify();
    const [empId, setEmpId] = useState("")
    const [name, setName] = useState("")
    const [emailId, setEmailId] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [stateId, setStateId] = useState(0)
    const [cols, setCols] = useState([]);
    const [griddata, setData] = useState([]);
    const [fetching, setFetching] = useState(false);  
    const [zipCodeID, setZipCodeID] = useState(0)
    const [businessId, setBusinessId] = useState(0)

    const [tags, setTags] = useState([])
    const [options, setOptions] = useState([])
    const [qualifications, setQualifications] = useState([])
    const [selectedQualifications, setSelectedQualifications] = useState([])

    const [keyskilltags, setKeySkillsTags] = useState([])
    const [keyskilloptions, setKeySkillOptions] = useState([])
    const [keyskills, setKeySkills] = useState([])
    const [selectedkeyskills, setSelectedKeySkills] = useState([])



    const [experiencetags, setExperienceTags] = useState([])
    const [experienceoptions, setExperienceOptions] = useState([])
    const [experiences, setExperiences] = useState([])
    const [selectedExperiences, setSelectedExperiences] = useState([])



    const [jobTitlestags, setJobTitlesTags] = useState([])
    const [jobTitlesoptions, setJobTitlesOptions] = useState([])
    const [jobTitles, setJobTitles] = useState([])
    const [selectedJobTitles, setSelectedJobTitles] = useState([])


    const [workAuthorizationstags, setWorkAuthorizationTags] = useState([])
    const [workAuthorizationoptions, setWorkAuthorizationOptions] = useState([])
    const [workAuthorizations, setWorkAuthorizations] = useState([])
    const [selectedWorkAuthorizations, setSelectedWorkAuthorizations] = useState([])

    const handleChange = async (value) => {

        let data = [...tags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedQualifications(data[data.length - 1])
        }
        if (value == null) {
            setSelectedQualifications([])
        }
        setTags([...data])
    };

    const handleKeySkillChange = async (value) => {
        
        let data = [...keyskilltags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedKeySkills(data[data.length - 1])
        }
        if (value == null) {
            setSelectedKeySkills([])
        }
        setKeySkillsTags([...data])
    };

    const handleExperienceChange = async (value) => {
        
        let data = [...keyskilltags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedExperiences(data[data.length - 1])
        }
        if (value == null) {
            setSelectedExperiences([])
        }
        setExperienceTags([...data])
    };

    const handleJobTitleChange = async (value) => {
        
        let data = [...jobTitlestags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedJobTitles(data[data.length - 1])
        }
        if (value == null) {
            setSelectedJobTitles([])
        }
        setJobTitlesTags([...data])
    };

const handleWorkAuthorizationChange = async (value) => {
        
        let data = [...workAuthorizationstags];
        if (value != null) {
            data.push(value);
        }
        if (data.length > 0) {

            setSelectedWorkAuthorizations(data[data.length - 1])
        }
        if (value == null) {
            setSelectedWorkAuthorizations([])
        }
        setWorkAuthorizationTags([...data])
    };
    
    

    useEffect(() => {
        loadQualifications()
        loadKeyskills();
        loadExperinces();
        loadJobTitles();
        loadWorkAuthorization();
        
    }, []);
    const loadQualifications = async () => {
        let resource=`qualifications?filter[offset]=0&filter[limit]=100000&filter[order]=name%20ASC`
        
        apiClient.getdata_v1(resource).then((res) => {
            let data = [];
            let dataoption = [];
            setQualifications(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setOptions(dataoption);
        }
        )
    } 
    const handleSelectAll = async (e) => {
        let data = [...griddata];
        for (let index = 0; index < data.length; index++) {
            data[index].selected = e.target.checked;
        }
        setData(data);
    }
    const handleSelect = async (e, rowIndex) => {
        let data = [...griddata];
        data[rowIndex].selected = e.target.checked;
        setData(data);
    }
    const handleSubmit = async () => { 
        let data = [...griddata];
        let selectedData = []
        selectedData = data.filter(x => x.selected === true);
        if (selectedData === undefined || selectedData.length === 0) {
            alert("Please select Resource to save");
            return;
        }
        let UserId = localStorage.getItem("userId");
        
        if (UserId) {
            UserId=parseInt(UserId);
            
        }
        let formData = [];
        for (let index = 0; index < selectedData.length; index++) {
            formData.push({ id: 0, RequirementID: rest.requirementId, CandidateID: selectedData[index].CandidateID ,UserId:UserId })
        }
        
        apiClient.addCandidate({formData }).then((res) => {
            if (res[0].result === true) {
                notify(res[0].message);
                rest.onClose();
            }
            else { alert(res[0].message); }
        }
        )

    }
    const searchResource = async () => {
        let _keyskills = [...selectedkeyskills]
        let _qualifications = [...selectedQualifications]
        let _experinces = [...selectedExperiences]
        let _workAutorization = [...selectedWorkAuthorizations]
        let _jobSkills = [...selectedJobTitles]
        let qualificationsstr = "";
        let keyskillsstr = "";
        let experincesstr = "";
        let workAuthorizationsstr="";
        let jobTitlesstr="";

        for (let index = 0; index < _qualifications.length; index++) {
            let s = _qualifications[index];
            let qualificationItem = qualifications.filter(x => x.name === s);
            if (qualificationItem != undefined && qualificationItem != null && qualificationItem.length > 0) {
                if (qualificationsstr.length === 0) {
                    qualificationsstr = qualificationItem[0].id;
                }
                else {
                    qualificationsstr = `${qualificationsstr},${qualificationItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _keyskills.length; index++) {
            let s = _keyskills[index];
            let keyskillsItem = _keyskills.filter(x => x.name === s);
            if (keyskillsItem != undefined && keyskillsItem != null && keyskillsItem.length > 0) {
                if (keyskillsstr.length === 0) {
                    keyskillsstr = keyskillsItem[0].id;
                }
                else {
                    keyskillsstr = `${keyskillsstr},${keyskillsItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _experinces.length; index++) {
            let s = _experinces[index];
            let experinceItem = _experinces.filter(x => x.name === s);
            if (experinceItem != undefined && experinceItem != null && experinceItem.length > 0) {
                if (experincesstr.length === 0) {
                    experincesstr = experinceItem[0].id;
                }
                else {
                    experincesstr = `${experincesstr},${experinceItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _workAutorization.length; index++) {
            let s = _workAutorization[index];
            let experinceItem = _experinces.filter(x => x.name === s);
            if (experinceItem != undefined && experinceItem != null && experinceItem.length > 0) {
                if (workAuthorizationsstr.length === 0) {
                    workAuthorizationsstr = experinceItem[0].id;
                }
                else {
                    workAuthorizationsstr = `${experincesstr},${experinceItem[0].id}`
                }
            }
        }
        for (let index = 0; index < _jobSkills.length; index++) {
            let s = _jobSkills[index];
            let experinceItem = _experinces.filter(x => x.name === s);
            if (experinceItem != undefined && experinceItem != null && experinceItem.length > 0) {
                if (jobTitlesstr.length === 0) {
                    jobTitlesstr = experinceItem[0].id;
                }
                else {
                    jobTitlesstr = `${experincesstr},${experinceItem[0].id}`
                }
            }
        }
        let UserId = localStorage.getItem("userId");
        
        if (UserId) {
            UserId=parseInt(UserId);
            
        }
       
        let formData = {
            RequirementID: rest.requirementId, 
            EmailID: emailId,
            MobileNo: mobileNo,
            Skills: keyskillsstr,
            Experiences:experincesstr, 
            Qualifications: qualificationsstr, 
            UserId:UserId,
            StateID: stateId,
            ZipCodeID: zipCodeID,
            WorkAuthorizations:workAuthorizationsstr,
            JobTitles:jobTitlesstr,
        }
 

        setFetching(true);
        apiClient.searchcandidate(formData).then((res) => {
            setData(res);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                let data = [];
                for (let index = 0; index < keys.length; index++) {
                    if (keys[index] != "RequirementID" && keys[index] != "CandidateID") {
                        data.push(keys[index])
                    }
                }
                setCols(data);
            }
            setFetching(false);
        }
        )
    }
    const loadKeyskills = async () => {
        let resource=`skills?filter[offset]=0&filter[limit]=10000&filter[order]=name%20ASC`
        apiClient.getdata_v1(resource).then((res) => {
            let data = [];
            let dataoption = [];
            setKeySkills(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setKeySkillOptions(dataoption);

        }
        )
    }
    const loadExperinces = async () => {
        let resource='experiences?filter[offset]=0&filter[limit]=1000&filter[order]=name%20ASC'
        apiClient.getdata_v1(resource).then((res) => {
            let data = [];
            let dataoption = [];
            setExperiences(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setExperienceOptions(dataoption);

        }
        )
    }
    const loadJobTitles = async () => {
        let resource='job-titles?filter[offset]=0&filter[limit]=1000&filter[order]=name%20ASC'
        apiClient.getdata_v1(resource).then((res) => {
            let data = [];
            let dataoption = [];
            setJobTitles(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setJobTitlesOptions(dataoption);

        }
        )
    }
    const loadWorkAuthorization = async () => {
        let resource='workauthorizations?filter[offset]=0&filter[limit]=1000&filter[order]=name%20ASC'
        apiClient.getdata_v1(resource).then((res) => {
            let data = [];
            let dataoption = [];
            setWorkAuthorizations(res);
            data = res;
            data.map(item => dataoption.push(item.name));
            setWorkAuthorizationOptions(dataoption);

        }
        )
    }
    
    
    const classes = useStyles({});
    return (
        <SimpleForm variant="outlined" toolbar={null}  >
            <div><span style={{fontSize:"22"}}><h4>Search Candidate</h4></span></div>
            
            <TextInput source="emailId" label="Email" fullWidth={true} variant="outlined" formClassName={classes.first_inline_input} onChange={(e) => { setEmailId(e.target.value); }} />
            <TextInput source="triviumemailId" label="Mobile No " fullWidth={true} variant="outlined" formClassName={classes.last_inline_input} onChange={(e) => { setMobileNo(e.target.value); }} /> 
            <ReferenceInput   sort={{ field: 'name', order: 'ASC' }}  label="State" source="stateId" reference="states" variant="outlined" fullWidth={true} formClassName={classes.first_inline_input} onChange={(e)=>{setStateId(e);}} > 
                <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput> 
            <ReferenceInput   label="ZipCode" source="zipCodeId" reference="zip-codes" variant="outlined" fullWidth={true} formClassName={classes.last_inline_input} onChange={(event)=>{setZipCodeID(event)}} >
            <AutocompleteInput variant="outlined" optionText="zipCode" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            
            
            <div className='multiselect-container'>
                <MultipleSelect label="Choose Qualification" values={tags} options={options}
                    helperText="You can search multiple Qualification" onChange={handleChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Qualification are selected", msgNoOptionsMatchFilter: "No Qualification name matches the filter", }} />
            </div>
            <div className='multiselect-container'>
                <MultipleSelect label="Choose  Skills" values={keyskilltags} options={keyskilloptions}
                    helperText="You can search multiple  Skills" onChange={handleKeySkillChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All  Skill are selected", msgNoOptionsMatchFilter: "No Skill name matches the filter", }} />
            </div>
            <div className='multiselect-container'>
                <MultipleSelect label="Choose Experience" values={experiencetags} options={experienceoptions}
                    helperText="You can search multiple experiences " onChange={handleExperienceChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All experiences are selected", msgNoOptionsMatchFilter: "No Experience matches the filter", }} />
            </div>
            <div className='multiselect-container'>
                <MultipleSelect label="Choose Job Title" values={jobTitlestags} options={jobTitlesoptions}
                    helperText="You can search multiple Job Titles " onChange={handleJobTitleChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All Job Titles are selected", msgNoOptionsMatchFilter: "No Experience matches the filter", }} />
            </div>
            <div className='multiselect-container'>
                <MultipleSelect label="Choose Work Authorization" values={workAuthorizationstags} options={workAuthorizationoptions}
                        helperText="You can search multiple work authorization " onChange={handleWorkAuthorizationChange}
                    SelectProps={{ isCreatable: false, msgNoOptionsAvailable: "All work authorization are selected", msgNoOptionsMatchFilter: "No work authorization matches the filter", }} />
            </div>
            <div className='multiselect-container-controls'>
                <div style={{ marginRight: "10px" }} ><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={searchResource}  >Search</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={handleSubmit} >Save</Button></div>
                <div style={{ marginRight: "10px" }}><Button variant="contained" color="primary" style={{ leftMargin: "10px" }} onClick={() => rest.onCancel()} >Back</Button></div>

            </div>
            {fetching && <div ><CircularProgress /></div>}
            {griddata && griddata.length > 0 && <div className='candidate-container' >
                <table   className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny font-size-12">
                    <tr>
                        <th key={0}><input type="checkbox" onChange={(e) => handleSelectAll(e)} /> </th>
                        {cols && cols.map((item, index) => {
                            return (
                                <th key={item}>{item}</th>
                            )
                        })}
                    </tr>

                    {griddata && griddata.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex}>

                                <td key={0}><input type="checkbox" checked={row.selected != undefined && row.selected != null ? row.selected : false} onChange={(e) => handleSelect(e, rowIndex)} /> </td>
                                {cols && cols.map((col, colIndex) => {
                                    return (
                                        <td key={rowIndex + "-" + colIndex}>
                                            {col!="ResumeURL"?row[col]:<a href={row[col]}  target='_blank' >View </a>}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </table>
            </div>
            }

        </SimpleForm>

    )
}
