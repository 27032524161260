import React, {useState, useCallback,useEffect } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput,SelectInput,
    DateInput, BooleanInput, NumberInput,ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { FormToolbar } from "../../common/FormToolbar";
import * as apiClient from "../../common/apiClient";
import { StageFields } from './StageFields';
const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const ModuleCreate  = (props) => {
    useEffect(() => {
        loadStatus();
    }, []);
    const loadStatus = () => {
        apiClient.getdata_v1 ("lookups?filter[where][type]=PLCH").then(res => {
            setStatues(res);
        })
    }
    const [statues, setStatues] = useState(null);
   return (<Create {...props} title="Selection Process" redirect={false} successMessage="Selection Process updated" undoable={false}>
            <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />} 
            variant="outlined">
            <TextInput source="name" label="Name" fullwidth={true}/>
            <TextInput source="description" label="Description"  fullwidth={true}/>
            <div style={{width:"100%"}} ><h4>System Fields for mail content:-</h4></div>
                <div style={{width:"100%"}} >
                {statues!=null && statues[0]?.name}
                </div>
            <RichTextInput source="candidateMailContents" label="Candidate Mail Contents" />
            <RichTextInput source="returnMailContents" label="Recruiter Mail Contents " />
            <RichTextInput source="internalMailContents" label="Interviewer Mail Contents " /> 
            {/* <BooleanInput initialValue={true} source="isApplisApplicableForInterviewer" label="Is Applicable For Interviewer"  fullwidth={true} />
             <BooleanInput initialValue={true} source="isAssignedToRecruiter" label="Is Applicable For Recruiter"  fullwidth={true} />  */}
              <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <StageFields {...formDataProps}

                            
                        />
                    )}
                </FormDataConsumer>

            {/*<BooleanInput initialValue={true} source="isAssignedToEvaluator" label="Assign Evaluator"  fullwidth={true} /> */}
            <BooleanInput initialValue={true} source="isActive" label="Is Active"  fullwidth={true} /> 
            </SimpleForm>
        </Create>)
   ;
}