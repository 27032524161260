import { Theme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type IframeGoogleDocsProps = {
    url: string,
};

export const FileViewer = (props: any) => {

    const [iframeTimeoutId, setIframeTimeoutId] = useState<any>();
    const [isDocLoading, setIsDocumentLoading] = useState<any>(true);
    const iframeRef: any = useRef(null);
    let taskFetchTimer = useRef<any>(null);


    const getIframeLink = useCallback(() => {
        
        return `https://docs.google.com/viewer?url=${props.record.resumeUrl}&embedded=true`;
    }, [props.record.resumeUrl])

    const updateIframeSrc = useCallback(() => {
        if (!props.record.resumeUrl) return null;
        //console.log("Refresh.....");
        if (iframeRef.current) {
            iframeRef!.current!.src = getIframeLink();
        }
    }, [getIframeLink])


    useEffect(() => {
        
        if (!props.record.resumeUrl) return;
        const intervalId = setInterval(
            updateIframeSrc, 1000 * 2
        );
        taskFetchTimer.current = intervalId;
        //console.log(taskFetchTimer.current, "Timer Started.....");
    }, [updateIframeSrc])

    function iframeLoaded() {
        //console.log(taskFetchTimer.current, "Loaded.....");
        clearInterval(taskFetchTimer.current);
        setIsDocumentLoading(false);
    }

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    const checkIfImage = (url: string) => {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }
    if (!props.record.resumeUrl) return null;


    if (checkIfImage(props.record.resumeUrl)) {
        return isXsmall ? (<img src={props.record.resumeUrl} style={{ width: "100%", height: "auto", objectFit: "contain" }} />) :
            (<img src={props.record.resumeUrl} style={{ width: "100%", height: "100%", objectFit: "contain" }} />)
    }
    else
        return (
            <>
                <iframe src={getIframeLink()}
                    onLoad={iframeLoaded}
                    onError={updateIframeSrc}
                    ref={iframeRef}
                    frameBorder="no" style={{ width: "100%", height: "100%" }} />
                {isDocLoading && (
                    <CircularProgress
                        size={50}
                        thickness={5}
                        style={{marginLeft:50}}
                    />
                )}
            </>
        )
}
