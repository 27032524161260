import React from "react";
import { JsonSchemaForm } from "react-jsonschema-form";

import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,DateInput,
  FormDataConsumer
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
import { AddressFields } from "./AddressFields";
export const ActivityCreate = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Create"); 
  
  let userID = localStorage.getItem("userId");
  let defaultValues = {
    createdDate: new Date(),
    createdBy: userID,
    isActive: true
  };
  return (
    <Create {...propsObj}> 
      <SimpleForm redirect="list" initialValues={defaultValues}
        toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" > 
         
       <TextInput source="zipCode" label="Zip Code"  fullWidth={true}  validate={[required()]} /> 
       <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <AddressFields {...formDataProps}

                            showAsAbove={true}
                        />
                    )}
                </FormDataConsumer>
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};
