// Install necessary packages
// npm install @react-pdf-viewer
// npm install @react-pdf/renderer

// Create a component for the PDF invoice
import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../common/apiClient";
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  headerSection: {
    marginBottom: 20,
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    textDecoration: 'underline',
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
  },
  text: {
    marginBottom: 5,
    fontSize: 12,
  },
  boldtext:{
    fontWeight:'extrabold',
    fontSize: 13,
  },
  table: {
    display: 'table',
    width: 'auto',
    margin: '10px 0',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    padding: 5,
  },
  signatureSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  signatureBlock: {
    width: '45%',
    textAlign: 'left',
    marginLeft:13
  },
  logo: {
    width: 100,
    height: 50,
    marginBottom: 10,
  },
  supplierSection:
  {
    marginLeft:30
  }

});
const PO = ({ data }) => {
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerSection}>
        <Image style={styles.logo} src="path_to_logo_image.png" />
        <Text style={styles.title}>
          {data[0].Header}
        </Text>
        <Text style={[styles.text,{textAlign:'left'}]}>
        {data[0].Line1}
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>
          1. Scope of Services
        </Text>
        <View style={styles.supplierSection} >
        <Text style={styles.text} >
          Supplier shall perform those assignments, tasks, and services requested by ST Global LLC that are necessary to meet the objectives of the engagement.
        </Text></View>
        <Text style={[styles.text,{marginTop:10}]}>
          <Text style={styles.boldtext} >Name of the Consultant:</Text> {data[0].Consultant}
        </Text>
        <Text style={styles.text}>
        <Text style={styles.boldtext} >End Client:</Text> {data[0].EndClientName}  
        </Text>
        <Text style={styles.text}>
        <Text style={styles.boldtext} >Work Location:</Text> {data[0].WorkLocation}  
        </Text>
        <Text style={styles.text}>
        <Text style={styles.boldtext} >Estimated Start Date:</Text> {data[0].EstimatedStartDate}  
        </Text>
        <Text style={styles.text}>
        <Text style={styles.boldtext} >Duration: </Text>{data[0].DurationsInWeek}
        </Text>
        <Text>{" "}</Text>
        <Text style={styles.text}>
        <Text style={styles.boldtext} >Compensation</Text>
        </Text>
        <View style={styles.supplierSection}>
        <Text style={styles.text}>
          The rate applicable to all services, the estimated utilization of hours and extended cost are as follows:
        </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Rate per hour</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Estimated no. of hours</Text>
            </View>
            
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data[0].RatePerHour}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data[0].EstimatedHours}</Text>
            </View>
            
          </View>
        </View>
        <View style={{marginTop:20}}>
        <Text style={styles.text}>
          Time Sheet submittal cycle: {data[0].TimeSheetCycle}
        </Text>
        <Text style={styles.text}>
          ST Global LLC shall pay Supplier on the basis of:  {data[0].PayBasis}
        </Text>
        <Text style={styles.text}>
          Invoicing Cycle: {data[0].InvoiceCycle}
        </Text>
        </View>
      </View>
      <View style={styles.signatureSection}>
        <View style={styles.signatureBlock}>
          <Text style={styles.text}>ST Global, LLC.</Text>
          <Text style={styles.text}>Signature: ____________________</Text>
          <Text style={styles.text}>Name: {data[0].POSignatoryName}</Text>
          <Text style={styles.text}>Title: {data[0].POSignatoryDesignation}</Text>
          <Text style={styles.text}>Date: {data[0].POSignatoryDate}</Text>
        </View>
        <View style={styles.signatureBlock}>
          <Text style={styles.text}>Company Name: {data[0].ClientName}</Text>
          <Text style={styles.text}>Signature: ____________________</Text>
          <Text style={styles.text}>Name: {data[0].POClientSignatoryName}</Text>
          <Text style={styles.text}>Title:  {data[0].POClientSignatoryDesignationID}</Text>
          <Text style={styles.text}>Date: {data[0].POClientSignatoryDate}</Text>
        </View>
      </View>
    </Page>
  </Document>
  );
};
const PrintPO = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [finalData, setFinalData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    LoadData(props.record.id)
  }, []);
  const LoadData = async (id) => {
    let payload = { id: parseInt(id) }
    setLoading(true)
    await apiClient.PrintPo(payload.id).then(res => { 
      //let data = JSON.parse(res);        
      setHeaderData(res);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }


  return (
    <>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
        {/* {JSON.stringify(finalData)} */}
      </div>
      {!isLoading && <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <PO data={headerData}  />
      </PDFViewer>
      }
    </>

  );
};

export default PrintPO;
