import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,
    Filter,

    SearchInput,
}
    from 'react-admin';
import { Authorizer } from "../../common/Authorizer";
import CDeleteButton from '../../common/CDeleteButton';
import { DisplayDateTime } from '../../common/DisplayDate';
const Filters = (props) => (
    <Filter { ...props } variant="outlined">

        <SearchInput
            variant="outlined"
            label="Role Name "
            source="roleName~like"
            alwaysOn
        />

    </Filter>
);
export const RoleTypesList = props => {
    const propsObj = Authorizer(props, "List");
    return (
        <List  { ...propsObj }  exporter={false} bulkActionButtons={false} filter={ { 'id~neq': 11 } } filters={ <Filters /> }>
            <Datagrid rowClick={ propsObj.hasEdit ? 'edit' : false }>
                <EditButton/>
                { propsObj.hasDelete && <CDeleteButton { ...props } /> }
                <TextField source="roleName" label="Role Name" />
                <ReferenceField source="roleType" reference="lookups" label="Role Type" link={ false }>
                    <TextField source="name" />
                </ReferenceField>

                <BooleanField source="isActive" label="Active" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={ false }>
                    <TextField source="userName" />
                </ReferenceField>

                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={ false }>
                    <TextField source="userName" />
                </ReferenceField>
                <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
            </Datagrid>
        </List>
    )
};