import React,{useState} from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Authorizer } from "../../common/Authorizer";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core'; 
import Button from '@material-ui/core/Button';
import PrintPO from "./PrintPO";
import PrintIcon from '@material-ui/icons/PrintOutlined';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 600,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1300,
  },
}));
const Filters = (props) => (
  <Filter {...props} variant="outlined">
     
    <SearchInput
      variant="outlined"
      label=" Po No"
      source="pono~like"
      alwaysOn
    />
    
  </Filter>
);
export const ActivityList = (props) => { 
  let propsObj = Authorizer(props,"List");  
  const myclasses = myStyles({});
  const [isPrint, setIsPrint] = useState(false); 
  const [selectRecord, setSelectRecord] = useState(null); 
  const handleClose =() => {
    
    setIsPrint(false);
   
  };
  const PrintButton = (props) => (
    <Button startIcon={<PrintIcon />} onClick={props.click} style={{ marginTop: 4 ,marginLeft: 4 }} color="primary" variant="outlined"  > Print   </Button>
  )
  const Action = (props) => {
    
    let { record } = props;
    
      return (
        <div style={{ display: "flex" }}>
          
          <PrintButton label="Generate Picklist " click={(e) => {setSelectRecord(record);setIsPrint(true)}} />
          
          
          
        </div>
      )
    }
    
     
  
  return (
    <React.Fragment>
    <List  {...propsObj} exporter={false}  bulkActionButtons={false}   filters={<Filters />}  >
      <Datagrid rowClick={false} >
        {propsObj.hasShow && !propsObj.hasEdit && <ShowButton />}
        {propsObj.hasShow && propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>}
        <Action label="Action" source="id" /> 
        <TextField source="pono" label="PO No" /> 
        <ReferenceField source="agentId" reference="agents" label="Agent"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="clientId" reference="clients" label="Client"  link={false}>
                <TextField source="name" />
            </ReferenceField>
        
        <BooleanField source="isActive" label="Active"/>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{paper: myclasses.drawerPaper}}
    open={isPrint} > 
 {selectRecord  && isPrint && <PrintPO
          onClose={handleClose}
          onCancel={handleClose} 
          record={selectRecord}
          {...props}
           />}
  
           
  </Drawer>
    </React.Fragment>
  );
};
