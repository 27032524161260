import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,DateInput,FormDataConsumer
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
import { AddressFields } from "./AddressFields";

  
export const ActivityEdit = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Edit"); 
  
  return (
    <Edit {...propsObj}  >
      
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        
      <TextInput source="zipCode" label="Zip Code"  fullWidth={true}  validate={[required()]} /> 
       <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <AddressFields {...formDataProps}

                            showAsAbove={true}
                        />
                    )}
                </FormDataConsumer>
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
      </SimpleForm>
      
    </Edit>
  );
};
