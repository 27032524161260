import React, { useState, useEffect, useCallback } from 'react';
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField, FileInput, FileField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, AutocompleteInput
} from 'react-admin';
import { AddressFields } from "./AddressFields";
import Button from '@material-ui/core/Button';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common//CDeleteButton";
import { CreateToolbar } from "../../common/CreateToolbar";

import { Drawer } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ExperienceCreate } from './ExperienceCreate';
import ExperienceEdit from './ExperienceEdit';
import JobTitleEdit from './JobTitleEdit';
import { JobTitleCreate } from './JobTitleCreate';
import { QualificationCreate } from './QualificationCreate';
import QualificationEdit from './QualificationEdit';
import { SkillCreate } from './SkillsCreate';
import SkillEdit from './SkillEdit';
import { CandidateClientEdit } from './CandidateClientEdit';

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: '50%',
    },
}));
export const CandidateEdit = props => {

    const [isAddExperience, setIsAddExperience] = useState(false);
    const [isEditExperience, setIsEditExperience] = useState(false);

    const [isAddJobTitle, setIsAddJobTitle] = useState(false);
    const [isEditJobTitle, setIsEditJobTitle] = useState(false);


    const [isAddQualification, setIsAddQualification] = useState(false);
    const [isEditQualification, setIsEditQualification] = useState(false);


    const [isAddSkill, setIsAddSkill] = useState(false);
    const [isEditSkill, setIsEditSkill] = useState(false);

    const [isEditEmployment, setIsEditEmployment] = useState(false);


    const [IsEditRecord, setIsEditRecord] = useState(null);
    const myclasses = myStyles({});
    const classes = useStyles({});
    const refresh = useRefresh();
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });
    const CustomEdit = (props) => {
        let { record } = props;
        return <Button
            color="secondary" disableElevation
            size="medium"

            onClick={() => {
                props.onClick(record);
            }}
            startIcon={<EditIcon />}>Edit</Button>
    }
    const onCloseForm = () => {
        setIsAddExperience(false)
        setIsEditExperience(false)
        setIsAddJobTitle(false)
        setIsEditJobTitle(false)
        setIsAddQualification(false)
        setIsEditQualification(false)
        setIsAddSkill(false)
        setIsEditSkill(false)
        setIsEditEmployment(false);
        
        refresh();
    }
    const onCancel = () => {
        setIsAddExperience(false)
        setIsEditExperience(false)
        setIsAddJobTitle(false)
        setIsEditJobTitle(false)
        setIsAddQualification(false)
        setIsEditQualification(false)
        setIsAddSkill(false)
        setIsEditSkill(false)
        setIsEditEmployment(false);
       
    }

    return (
        <>
            <React.Fragment>
                <Edit {...props} title="Edit Imported Candidate  " undoable={false}>
                    <TabbedForm variant="outlined" toolbar={<CreateToolbar {...props} mode={"Edit"} />}>
                        <FormTab value="DETAILS" label="Detail">

                        <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
                <TextInput source="name" fullWidth={true} label="Name" validate={[required()]} formClassName={classes.last_inline_input} />


                <TextInput source="address1" label="Address Line 1" fullWidth={true} multiline   formClassName={classes.first_inline_input} />
                <TextInput source="address2" label="Address Line 2" fullWidth={true} multiline formClassName={classes.last_inline_input} />
                <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <AddressFields {...formDataProps}

                            showAsAbove={true}
                        />
                    )}
                </FormDataConsumer>
                <TextInput source="personnelEmailId" label="Personal Email " fullWidth={true} validate={[required(), email()]} formClassName={classes.one_three_input} />
                <TextInput source="alternateEmailId" label="Alternate Email " fullWidth={true} validate={[ email()]} formClassName={classes.two_three_input} />
                <TextInput source="contactNo" label="Contact No " fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

                <TextInput source="alternateContactNo" label="Alternate Contact No" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="ssnno" label="SSNNO " fullWidth={true} formClassName={classes.two_three_input} />
                <div></div>


                <ReferenceInput perPage={25} variant="outlined" label="Highest Qualification "  filterToQuery={searchText => ({ 'name~like': searchText })} source="highestQualificationId" reference="qualifications" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>


                {/* <ReferenceInput perPage={25} variant="outlined" label="Current Location State " source="currentLocationStateId" reference="states" fullWidth={true} formClassName={classes.two_three_input}  sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput> */}
                <BooleanInput source="relocation" label="Relocation" fullWidth={true} formClassName={classes.two_three_input} />
                <div></div>


                <ReferenceInput perPage={25} variant="outlined" label="Work Authorization"  filterToQuery={searchText => ({ 'name~like': searchText })} source="workauthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.one_three_input}  validate={[required()]}  sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

                <ReferenceInput perPage={25} variant="outlined" label="Job Title"  validate={[required()]}  
                filterToQuery={searchText => ({ 'name~like': searchText })} source="preferredJobTitleId" reference="job-titles" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

                <FormDataConsumer fullWidth={true} formClassName={classes.last_three_input}>
                    {({ formData, ...rest }) =>
                    
                        (formData.workauthorizationId &&  formData.workauthorizationId === 4) ? (
                            <ReferenceInput perPage={25} variant="outlined" label="Current Employment " source="currentEmploymentId"
                                reference="agents"
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                fullWidth={true} formClassName={classes.last_three_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                            </ReferenceInput>
                        ) : (
                            <ReferenceInput perPage={25} variant="outlined" label="Current Employment" source="currentEmploymentId"
                                reference="agents"
                                filterToQuery={searchText => ({ 'name~like': searchText })}
                                fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
                                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => val && val.trim().length >= 1} filterToQuery={filterToQuery} emptyValue={''} />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <NumberInput source="salaryinUsd" label="Salary/Rate " fullWidth={true} formClassName={classes.one_three_input} />
                <ReferenceInput perPage={25} variant="outlined" label="Hourly Rate" source="hourlyRateId"
                    reference="lookups"
                    
                    sort={{ field: 'name', order: 'asc' }}
                    filter={{ type: 'HRLRT' }}

                    fullWidth={true} formClassName={classes.two_three_input}       >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput perPage={25} variant="outlined" label="Work Setting" source="workSettingId"
                    reference="lookups"
                    
                    sort={{ field: 'name', order: 'asc' }}
                    filter={{ type: 'WKSET' }}

                    fullWidth={true} formClassName={classes.last_three_input}      >
                    <SelectInput optionText="name" />
                </ReferenceInput>



                <ReferenceInput perPage={25} variant="outlined" label="Work Experience" source="workExperienceId" reference="experiences" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }}
                 filterToQuery={searchText => ({ 'name~like': searchText })}
                 >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

                <ReferenceInput perPage={25} variant="outlined" label="Top Skill" source="topSkillId" reference="skills" fullWidth={true} formClassName={classes.two_three_input}  sort={{ field: 'name', order: 'ASC' }}
                 filterToQuery={searchText => ({ 'name~like': searchText })}
                 >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>

                <ReferenceInput perPage={25} variant="outlined" label="Preferred Job Title" source="secondarySkillId" reference="skills" fullWidth={true} formClassName={classes.last_three_input}   sort={{ field: 'name', order: 'ASC' }}
                 filterToQuery={searchText => ({ 'name~like': searchText })}
                 >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>



                <BooleanInput source="isSignedPaper" label="Signed Paper" fullWidth={true} formClassName={classes.one_three_input} 
                 filterToQuery={searchText => ({ 'name~like': searchText })}
                />
                
                <ReferenceInput label="Status" source="statusId" fullWidth={true} reference="lookups"  filter={{ type: 'EMPLOYEEROLE' }}   formClassName={classes.two_three_input}>
              <SelectInput optionText="name"   />
            </ReferenceInput>

                <div>

                </div>
                {<FileInput source="resumeUrl1" formClassName={classes.first_inline_input}
                    label="Resume" className="logourl">
                    <FileField source="resumeUrl1" title="Resume" />
                </FileInput>

                }
                <FileField source="resumeUrl" label="" title="Download Resume" formClassName={classes.last_inline_input} />

                <div>


                </div>
                {<FileInput source="covernoteUrl1" formClassName={classes.first_inline_input}
                    label="Cover Note" className="logourl">
                    <FileField source="covernoteUrl1" title="Cover Note" />
                </FileInput>

                }
                <FileField source="covernoteUrl" label="" title="Download Cover Note" formClassName={classes.last_inline_input} />

                <TextInput source="remarks" label="Remarks" fullWidth={true} multiline formClassName={classes.one_three_input} />
                <TextInput source="dlStateId" label="DL/State ID" fullWidth={true}  formClassName={classes.two_three_input} />
                <TextInput source="linkedInId" label="LinkedIn Id" fullWidth={true}  formClassName={classes.last_three_input} />

                        </FormTab>
                        {/* <FormTab value="DETAILS" label="Experience">
                            {<Button variant="contained" onClick={() => { setIsAddExperience(true); }}
                                color="secondary">
                                Add Experience
                            </Button>}
                            <ReferenceManyField perPage={100} reference="import-candidate-experiences" target="candidateId" addLabel={false} fullWidth={true}>
                                <Datagrid rowClick={false}>
                                    <CDeleteButton {...props} />
                                    <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditExperience(true); }} label="Edit" />


                                    <ReferenceField source="experienceId" reference="experiences" label="Experience" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab> */}
                        <FormTab value="DETAILS" label="Job Titles">
                            {<Button variant="contained" onClick={() => { setIsAddJobTitle(true); }}
                                color="secondary">
                                Add Job Title
                            </Button>}
                            <ReferenceManyField perPage={100} reference="import-candidates-job-titles" target="candidateId" addLabel={false} fullWidth={true}>
                                <Datagrid rowClick={false}>
                                    <CDeleteButton {...props} />
                                    <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditJobTitle(true); }} label="Edit" />
                                    <ReferenceField source="jobTitleId" reference="job-titles" label="Job Titles" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab>
                        <FormTab value="DETAILS" label="Qualifications">
                            {<Button variant="contained" onClick={() => { setIsAddQualification(true); }}
                                color="secondary">
                                Add Qualification
                            </Button>}
                            <ReferenceManyField perPage={100} reference="import-candidates-qualifications" target="candidateId" addLabel={false} fullWidth={true}>
                                <Datagrid rowClick={false}>
                                    <CDeleteButton {...props} />
                                    <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditQualification(true); }} label="Edit" />
                                    <ReferenceField source="qualificationId" reference="qualifications" label="Qualification" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab>
                        <FormTab value="DETAILS" label="Skills">
                            {<Button variant="contained" onClick={() => { setIsAddSkill(true); }}
                                color="secondary">
                                Add Skill
                            </Button>}
                            <ReferenceManyField perPage={100} reference="import-candidates-skills" target="candidateId" addLabel={false} fullWidth={true}>
                                <Datagrid rowClick={false}>
                                    <CDeleteButton {...props} />
                                    <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditSkill(true); }} label="Edit" />
                                    <ReferenceField source="skillId" reference="skills" label="Skill" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    
                                    <ReferenceField source="experienceId" reference="experiences" label="Experience" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>

                                    <TextField  source="lastUsedYears" label="Last Used Year"/> 
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab>
                        
                        
        
                    </TabbedForm>
                </Edit>

            </React.Fragment>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isAddExperience 
                    || isEditExperience
                    || isEditJobTitle 
                    || isAddJobTitle
                    || isEditQualification 
                    || isAddQualification
                    || isEditSkill 
                    || isAddSkill
                    || isEditEmployment
                }
                 >
                {isAddExperience &&
                    <ExperienceCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        candidateId={props.id}
                        {...props} />}

                {isEditExperience &&
                    <ExperienceEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}


                {isAddJobTitle &&
                    <JobTitleCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        candidateId={props.id}
                        {...props} />}

                {isEditJobTitle &&
                    <JobTitleEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />} 
            {isAddQualification &&
                    <QualificationCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        candidateId={props.id}
                        {...props} />}

                {isEditQualification &&
                    <QualificationEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}

{isAddSkill &&
                    <SkillCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        candidateId={props.id}
                        {...props} />}

                {isEditSkill &&
                    <SkillEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}

{isEditEmployment &&
                    <CandidateClientEdit
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        {...props} />}




            </Drawer>
        </>
    );
}