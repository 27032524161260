import React from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField, FileInput, FileField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";

  
export const ActivityEdit = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Edit"); 
  
  return (
    <Edit {...propsObj}  >
      
      <TabbedForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <FormTab value="DETAILS" label="Detail">
      <DateInput source="poDate" label="PO Date " fullWidth={true} formClassName={classes.one_4_input} />
      <TextInput source="pono" label="PO No " fullWidth={true} formClassName={classes.two_4_input} disabled />
          <div></div>
        <ReferenceInput perPage={25} variant="outlined" label="Agent" source="agentId" reference="agents" fullWidth={true} formClassName={classes.first_inline_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} emptyValue={''} />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Client" source="clientId" reference="clients" fullWidth={true} formClassName={classes.last_inline_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} emptyValue={''} />
        </ReferenceInput>
        <TextInput source="taxPayerIdNo" label="Tax Payer ID No " fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
        <TextInput source="endClientName" label="End Client Name " fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} />
        <DateInput source="estimatedStartDate" label="Estimated Start Date " fullWidth={true} formClassName={classes.three_4_input} />
        <DateInput source="estimatedEndDate" label="Estimated End Date " fullWidth={true} formClassName={classes.last_4_input} />

        <NumberInput source="durationsInWeek" label="Durations In Week " fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
        <NumberInput source="ratePerHour" label="Rate Per Hour " fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} />
        <NumberInput source="estimatedHouers" label="Estimated Hours" fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="Timesheet Cycle"
          source="timeSheetCycleId" reference="lookups"
          fullWidth={true} formClassName={classes.last_4_input}
          filter={{ type: 'POTMSHTCYCLE'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Pay Basis"
          source="payBasisId" reference="lookups"
          filter={{ type: 'POTMSHTCYCLE'}}
          fullWidth={true} formClassName={classes.one_4_input}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Invoice Cycle"
          source="invoiceCycleId" reference="lookups"
          fullWidth={true} formClassName={classes.two_4_input}
          filter={{ type: 'POTMSHTCYCLE'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="poSignatoryName" label="PO Signatory Name " fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="PO Signatory Designation"
          source="poSignatoryDesignationId" reference="lookups"
          filter={{ type: 'PODESIGNATION'}}
          fullWidth={true} formClassName={classes.last_4_input}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="poSignatoryDate" label="PO Signatory Date " fullWidth={true} formClassName={classes.one_4_input} />
        <TextInput source="poClientSignatoryName" label="PO Client Signatory Name " fullWidth={true} formClassName={classes.two_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="PO Client Signatory Designation"
          source="poClientSignatoryDesignationId" reference="lookups"
          fullWidth={true} formClassName={classes.three_4_input}
          filter={{ type: 'PODESIGNATION'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="poClientSignatoryDate" label="PO Client Signatory Date " fullWidth={true} formClassName={classes.last_4_input} />
        <DateInput source="contractDate" label="Contract Date " fullWidth={true} formClassName={classes.one_4_input} />
        <TextInput source="workLocation" label="Work Location " fullWidth={true} formClassName={classes.two_4_input} />


        <div>

        </div>
        {<FileInput source="contractUrl1" formClassName={classes.first_inline_input}
          label="Contract" className="logourl">
          <FileField source="contractUrl1" title="Contract" />
        </FileInput>

        }
        <FileField source="contractUrl" label="" title="Download Contract" formClassName={classes.last_inline_input} />

        <div>
        </div>
        </FormTab>
        <FormTab value="DETAILS" label="Candidates">
                            {/* {<Button variant="contained" onClick={() => { setIsAddSkill(true); }}
                                color="secondary">
                                Add Skill
                            </Button>} */}
                            <ReferenceManyField perPage={100} reference="candidates-client-maps" target="agentPoId" addLabel={false} fullWidth={true}>
                                <Datagrid rowClick={false}>
                                    {/* <CDeleteButton {...props} /> */}
                                    
                                    <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    
                                    <ReferenceField source="candidateId" reference="candidates" label="Candidate Code" link={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <ReferenceField source="candidateId" reference="candidates" label="Candidate" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="candidateId" reference="candidates" label="Personnel Email Id" link={false}>
                                        <TextField source="personnelEmailId" />
                                    </ReferenceField>
                                    

                                    <TextField  source="empCode" label="Emp Code"/> 
                                    <DisplayDateTime source="startDate" label="Start Date" locales="fr-FR" />
                                    <DisplayDateTime source="endDate" label="End Date" locales="fr-FR" />
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                    <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />

                                </Datagrid>
                            </ReferenceManyField>
                        </FormTab>
      </TabbedForm>
      
    </Edit>
  );
};
