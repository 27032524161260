import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    BooleanInput, required, NumberInput, AutocompleteInput
} from 'react-admin';
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
 
export const CandidateEmail = (props) => {
    debugger
    const classes = useStyles({});
    let userId=null;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
 
    // Initialize initial form values
    const initialValues = {
        toEmailId: userId,
    };

    // Handle form submission
    const handleSubmit = async (formData) => {
        const date = formData.date ? moment(formData.date).format() : null;
        let m = moment();
        const email = localStorage.getItem("email");
        const data = {
            ids: props.record.data.ids,
            ToMails:email ,
            CCMails: formData.ccEmail?formData.ccEmail:'',
            BCCMails: props.record.data.emails,
            Subjects: formData.subject,
            Body: formData.emailBody
            
        };
      
        apiClient.addSendEmail( data)
            .then(res => {
                console.log(res);
                props.onClose();
            })
            .catch(err => {
                console.error(err);
            });
    };
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });

    return (
        <div style={{overflowY:'scroll',height:'auto'}} >   
        <Create {...props} basePath="candidate-queries" resource="candidate-queries">
            
            <SimpleForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar onSave={handleSubmit} label="Save" onClose={props.onClose} />}
                variant="outlined"
            >
                  
                       
    <ReferenceInput perPage={25} variant="outlined" label="To Email" source="toEmailId"
      reference="users"  sort={{ field: 'name', order: 'ASC' }} disabled fullWidth={true}>
      <SelectInput optionText="email" />
    </ReferenceInput> 
    <TextInput source="ccEmail" label="CC Email " fullWidth={true} />
                <TextInput source="bccEmail" label="BCC Email " fullWidth={true} multiline disabled initialValue={props.record.data.emails} />
                <TextInput source="subject" label="Subject " fullWidth={true} validate={[required()]} />
                <RichTextInput source="emailBody" label="Email Content" fullWidth={true}validate={[required()]} />  
                
            </SimpleForm>
            
        </Create>
        </div>
    );
};
