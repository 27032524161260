import {ActivityList} from "./ActivityList";
import {CandidateEdit} from "./CandidateEdit";
import {CandidateCreate} from "./CandidateCreate";
import {CandidateView} from "./CandidateView";

export default {
    list: ActivityList,
    create: CandidateCreate,
    edit: CandidateEdit,
    show:CandidateView
    
};
