import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, AutocompleteInput
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { CreateToolbar } from "../../common/CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../common/formStyles";
import moment from "moment";

export const CandidateClientEdit = props => {
    const classes = useStyles({});
    

    const initialValues = {

    }
    const handleSubmit = (formdata) => {

        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
            userId = parseInt(userId);
        }
        let endDate = (formdata.endDate) ? moment(formdata.endDate).format() : null;
        let data = { ...formdata };
        data.endDate = endDate;
        let m = moment(new Date());
        if (data.createdBy == null) {
            data.createdBy = userId;
        }
        if (data.endDate == null) {
            delete data.endDate;
        }
        if (data.createdDate == null) {
            data.createdDate = m.toISOString();
        }

        data.modifyDate = m.toISOString()
        data.modifyBy = userId
        apiClient.updateRecord("candidates-client-maps", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
     
    return (
        <Edit {...props} id={props.record.id} resource="candidates-client-maps" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<CreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined">


                <TextInput source="empCode" label="Emp Code" fullWidth={true} disabled />
                <ReferenceInput label="Client" source="clientId"
                    reference="clients" disabled 
                    validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ isActive: 1 }} fullWidth={true}   >
                    <SelectInput variant="outlined" optionText="name" />
                </ReferenceInput>
                {/* agentPoId */}
                <DateInput source="startDate" disabled validate={required()} fullWidth={true} label="Start Date" formClassName={classes.first_inline_input} />
                <DateInput source="endDate" fullWidth={true} label="End Date" formClassName={classes.last_inline_input} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.clientId && (
                        <ReferenceInput
                            perPage={25}
                            variant="outlined"
                            label="Agent Po"
                            source="agentPoId"
                            reference="agent-pos"
                            fullWidth
                            formClassName={classes.one_three_input}
                            sort={{ field: 'pono', order: 'ASC' }}
                            filter={{ clientId: formData.clientId }}
                            {...rest}>
                            <AutocompleteInput optionText="pono" shouldRenderSuggestions={(val) => val?.trim().length >= 1}  />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>

            </SimpleForm>
        </Edit>
    );
}
