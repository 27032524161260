import React, { useState, useEffect, useCallback } from 'react';
import {
    required, minLength, maxLength, minValue, maxValue, useRefresh,
    number, regex, email, choices,
    TabbedForm, FormTab, ReferenceInput,
    Edit, SimpleForm, TextInput, RichTextField, FileInput,FileField,
    SelectInput, EditButton, NumberField, DateField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField,
} from 'react-admin';
import { AddressFields } from "./AddressFields";
import Button from '@material-ui/core/Button';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
 
import { CreateToolbar } from "../../common/CreateToolbar";
 
 
import EditIcon from '@material-ui/icons/Edit';
 

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 750,
    },
}));
export const AgentEdit = props => {


    const classes = useStyles({});
    
   
 


     

    
    //console.log(match);
    //console.log(location);
 
 
     
    return (
        <React.Fragment>
            <Edit redirect="list" {...props} title="Edit Client  " undoable={false}>
                <TabbedForm variant="outlined" toolbar={ <CreateToolbar {...props} mode={"Edit"} />}>
                    <FormTab value="DETAILS" label="Detail">

                    <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
                <TextInput source="name" fullWidth={true} label="Name" validate={[required()]} formClassName={classes.last_inline_input} />


                <TextInput source="address1" label="Address Line 1" fullWidth={true} multiline  formClassName={classes.first_inline_input} />
                <TextInput source="address2" label="Address Line 2" fullWidth={true} multiline formClassName={classes.last_inline_input} />
                <FormDataConsumer fullWidth={true}>
                    {formDataProps => (
                        <AddressFields {...formDataProps}

                            showAsAbove={true}
                        />
                    )}
                </FormDataConsumer>
                <TextInput source="contactPerson1" label="Primary Contact Person " fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
                <TextInput source="contactNo1" label="Primary Contact No " fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
                <TextInput source="contactEmail1" label="Primary Contact Email " fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

                <TextInput source="contactPerson2" label="Secondary Contact Person" fullWidth={true} formClassName={classes.one_three_input} />
                <TextInput source="contactNo2" label="Secondary Contact No " fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="contactEmail2" label="Secondary Contact Email " fullWidth={true} formClassName={classes.last_three_input} />


                <TextInput source="einno" label="Ein No " fullWidth={true} formClassName={classes.one_three_input} />
                <BooleanInput source="isSignedPaper" label="Signed Paper" fullWidth={true} formClassName={classes.two_three_input} />
                <DateInput source="signedDate" fullWidth={true} label="Signed Date"   formClassName={classes.last_three_input} />
                <ReferenceInput label="Signed Paper Type" source="signedPaperTypeId" reference="lookups"   sort={{ field: 'name', order: 'asc' }} filter={{ type: 'DT' }} fullWidth={true} formClassName={classes.one_three_input}  >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="taxpayerNo" label="TaxPayer No " fullWidth={true}  formClassName={classes.two_three_input} />
                <div>

                </div>
                <TextInput source="signedPaperName1" label="Signed Paper Name 1 " fullWidth={true}  formClassName={classes.one_three_input} />
                <div>

                </div>
                { <FileInput source="signedPaper1DocUrl1" formClassName={classes.first_inline_input}  
                            label="Signed Paper 1" className="logourl">
                            <FileField source="signedPaper1DocUrl" title="Signed Paper 1" />
                        </FileInput>
                        
                }
               <FileField source="signedPaper1DocUrl" label="" title="Download Signed Paper 1"   formClassName={classes.last_inline_input} />
               <TextInput source="signedPaperName2" label="Signed Paper Name 2 " fullWidth={true}  formClassName={classes.one_three_input} />
               
                <div>
                

                </div>
                { <FileInput source="signedPaper2DocUrl2" formClassName={classes.first_inline_input}  
                            label="Signed Paper 2" className="logourl">
                            <FileField source="signedPaper2DocUrl" title="Signed Paper 2" />
                        </FileInput>
                        
                        }
               <FileField source="signedPaper2DocUrl" label="" title="Download Signed Paper 2"   formClassName={classes.last_inline_input} />
               <ReferenceInput label="RM" source="rmid" reference="users" validate={required()} sort={{ field: 'userName', order: 'asc' }} fullWidth={true} formClassName={classes.one_three_input}  >
                    <SelectInput optionText="userName" />
                </ReferenceInput>
                
                
                    </FormTab>
                     
                </TabbedForm>
            </Edit>
           
        </React.Fragment>
    );
}