import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { Button } from '@material-ui/core';
import * as apiClient from "../../common/apiClient";
export const StageFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
     

    return (
        <div className="address-field-parent"> 
           <BooleanInput initialValue={true} source="isApplicableForInterviewer" label="Is Applicable For Interviewer"  fullwidth={true}  formClassName={classes.last_4_input}
           onChange={(e)=>{form.change("isAssignedToRecruiter",false)}}
           />
                <BooleanInput initialValue={true} source="isAssignedToRecruiter" label="Is Applicable For Recruiter"  fullwidth={true}  formClassName={classes.one_4_input} 
                onChange={()=>{form.change("isApplicableForInterviewer",false)}}
                /> 
                
                
            </div>
        
    )
}
