import React from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Authorizer } from "../../common/Authorizer";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";


const Filters = (props) => (
  <Filter {...props} variant="outlined">
     
    <SearchInput
      variant="outlined"
      label=" Name"
      source="name~like"
      alwaysOn
    />
    
  </Filter>
);
const rowStyle = (record, index) => {

  if (record.isSignedPaper ==true ) {
      return { backgroundColor: 'lightgreen' };
  }
  else {
      return { backgroundColor: 'inherit' };
  }
}
export const ActivityList = (props) => { 
  let propsObj = Authorizer(props,"Edit");  
  return (
    <List  {...propsObj}  exporter={false}  bulkActionButtons={false}    filters={<Filters />}  >
      <Datagrid rowClick={false}  rowStyle={rowStyle}>
        {propsObj.hasShow && !propsObj.hasEdit && <ShowButton />}
        {propsObj.hasShow && propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>} 
        <TextField source="name" label=" Name " />  
        <TextField source="contactPerson1" label="Primary Contact Person " />
                <TextField source="contactNo1" label="Primary Contact No " />
                <TextField source="contactEmail1" label="Primary Contact Email " />

                <TextField source="contactPerson2" label="Secondary Contact Person" />
                <TextField source="contactNo2" label="Secondary Contact No " />
                <TextField source="contactEmail2" label="Secondary Contact Email "  />


                <TextField source="einno" label="Ein No "  />
                <BooleanField source="isSignedPaper" label="Singed Paper" />
                <DisplayDateTime source="signedDate" />      
        <BooleanField source="isActive" label="Active"/>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
