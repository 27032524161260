import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput, AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../common/formStyles";

const QualificationEdit = props => {
    const classes = useStyles({}); 
    const handleSubmit = async (formdata) => {
        let data = formdata;
        let m = moment();
        const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0; 
        data.modifyDate = m.toISOString();
        data.modifyBy = userId;
        apiClient.updateEntiyRecord("candidates-qualifications", data.id, data)
            .then(res => {
                console.log(res);
                props.onClose();
            })
            .catch(err => {
                console.error(err);
            }); 
    }
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
    });
    return (
        <Edit {...props} id={props.record.id} resource="candidates-qualifications" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                  <ReferenceInput
                    label="Qualification"
                    source="qualificationId"
                    reference="qualifications"
                    validate={required()}
                    sort={{ field: 'name', order: 'asc' }}
                    fullWidth={true}
                >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} filterToQuery={filterToQuery} emptyValue={''} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}
export default QualificationEdit;