import React, { useState, useCallback, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';


export const ActionAdd = ({ formData, ...rest }) => {
    const form = useForm();
    const [candidates, setCandidates] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    
    const [isMailTrigger, setIsMailTrigger] = useState(false)
    const [isEvaluators, setIsEvaluators] = useState(false) 
    const [stages, setStages] = useState([])  

    useEffect(() => {
        form.change("candidateId", parseInt(rest.record.candidateId))
        loadStages();
        

    }, []);
     
    const loadStages = async () => {
        apiClient.GetRequirementStages(formData.requirementId,'IE').then((res) => {
            let data = [];
            let dataoption = [];
            setStages(res);

        }
        )
    }
     
    
    const handleChange = async (id) => {
        if (candidates.find(candidate => candidate.id === id)) {
            form.change("candidateId", null)
            console.log("Candidate already exists in the list");
            return;
        }
        await apiClient.getResource(`candidates/${id}`).then(res => {
            const candidates_data = [...candidates];
            candidates_data.push({ ...res });
            setCandidates([...candidates_data]);
            form.change("candidateId", null)
        }).catch(err => {
            console.log(err);
        });

    }
    const removeCustomTag = (index) => {
        const updatedTags = [...candidates];
        updatedTags.splice(index, 1);
        setCandidates(updatedTags);
    };
    return (
        <div >
            <div>
                <div>
                    
                    {/* <ReferenceInput label="Type" fullWidth={true} perPage={5000} source="typeId" reference="statuses">
                        <SelectInput optionText="status" variant="outlined" fullWidth={true} disabled />
                    </ReferenceInput> */}
                    <ReferenceInput disabled perPage={25} sort={{ field: 'name', order: 'ASC' }} variant="outlined" filterToQuery={searchText => ({ 'firstName~like': searchText })} validate={[required()]} label="Candidate" source="candidateId" reference="candidates" fullWidth={true} onChange={(val) => handleChange(val)} >
                        <SelectInput disabled optionText={choice => `${choice.name} `} />
                    </ReferenceInput>
                    
                    <SelectInput fullWidth={true} label="Stage" source="requirementsStageId" variant="outlined" optionText={choice => `${choice.name} `} choices={stages} onChange={(event) => {setIsMailTrigger(stages.filter(x => x.id == event.target.value)[0].isTestLink);setIsEvaluators(stages.filter(x => x.id == event.target.value)[0].isAssignedToEvaluator)}} /> 
                     
                    
                        <ReferenceInput label="Status" fullWidth={true}
                    perPage={5000} source="rstatusId"
                    initialValue={56}
                    reference="lookups"
                    
                    filter={{ type: 'RECRUITASGND'}}
                    sort={{ field: 'name', order: 'ASC' }}  
                >
                    <SelectInput  optionText="name" variant="outlined" fullWidth={true} validate={[required()]} />
                </ReferenceInput>
                    <TextInput source="remarks" variant="outlined"  label="Remarks" fullWidth={true} multiline />

                </div>




            </div>
        </div>
    )
}
