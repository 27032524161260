import React, { useState } from 'react';
import './FileUpload.css';
import * as apiClient from "../../common/apiClient";

export const ImportProfile = (props) => {
  const [files, setFiles] = useState([]);
  const [fileStatus, setFileStatus] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newFiles = selectedFiles.map(file => ({ file, status: 'Pending' }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleUpload = async () => {
    setIsUploading(true); // Show spinner
    const newFileStatus = {};

    for (const [index, fileWrapper] of files.entries()) {
      try {
        // Simulate the API call and set status
        
       let response= await apiClient.UploadFile("file", { rawFile: fileWrapper.file });
       let data={resumeUrl:response }
       await apiClient.addEntiyRecord("import-candidates",data);
        
        newFileStatus[index] = 'Uploaded';
      } catch (error) {
        newFileStatus[index] = 'Failed';
      }
    }

    setFileStatus(newFileStatus);
    setIsUploading(false); // Hide spinner
  };

  return (
    <div className="file-upload-container">
      <input
        type="file"
        multiple
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
        className="file-input"
      />
      <button onClick={handleUpload} className="upload-button" disabled={isUploading}>
        {isUploading ? 'Uploading...' : 'Process Files'}
      </button>
      
      {isUploading && <div className="loading-spinner"></div>}

      <div className="file-list">
        <h3>Files to be uploaded</h3>
        <table className="file-table">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {files.map((fileWrapper, index) => (
              <tr key={index} className="file-row">
                <td>{fileWrapper.file.name}</td>
                <td className={`file-status ${fileStatus[index] || fileWrapper.status.toLowerCase()}`}>
                  {fileStatus[index] || fileWrapper.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
