import React from "react";
import { JsonSchemaForm } from "react-jsonschema-form";

import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField, DateInput
  , AutocompleteInput, FileInput, FileField
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
export const ActivityCreate = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props, "Create");
  let userID = localStorage.getItem("userId");
  let defaultValues = {
    poDate: new Date(),
    createdDate: new Date(),
    createdBy: userID,
    isActive: true
  };
  return (
    <Create {...propsObj}>
      <SimpleForm redirect="list" initialValues={defaultValues}
        toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <DateInput source="poDate" label="PO Date " fullWidth={true} formClassName={classes.one_4_input} />
      
          <div></div>
        <ReferenceInput perPage={25} variant="outlined" label="Agent" source="agentId" reference="agents" fullWidth={true} formClassName={classes.first_inline_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} emptyValue={''} />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Client" source="clientId" reference="clients" fullWidth={true} formClassName={classes.last_inline_input} validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} emptyValue={''} />
        </ReferenceInput>
        <TextInput source="taxPayerIdNo" label="Tax Payer ID No " fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
        <TextInput source="endClientName" label="End Client Name " fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} />
        <DateInput source="estimatedStartDate" label="Estimated Start Date " fullWidth={true} formClassName={classes.three_4_input} />
        <DateInput source="estimatedEndDate" label="Estimated End Date " fullWidth={true} formClassName={classes.last_4_input} />

        <NumberInput source="durationsInWeek" label="Durations In Week " fullWidth={true} validate={[required()]} formClassName={classes.one_4_input} />
        <NumberInput source="ratePerHour" label="Rate Per Hour " fullWidth={true} validate={[required()]} formClassName={classes.two_4_input} />
        <NumberInput source="estimatedHouers" label="Estimated Hours" fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="Timesheet Cycle"
          source="timeSheetCycleId" reference="lookups"
          fullWidth={true} formClassName={classes.last_4_input}
          filter={{ type: 'POTMSHTCYCLE'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Pay Basis"
          source="payBasisId" reference="lookups"
          filter={{ type: 'POTMSHTCYCLE'}}
          fullWidth={true} formClassName={classes.one_4_input}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>


        <ReferenceInput perPage={25} variant="outlined" label="Invoice Cycle"
          source="invoiceCycleId" reference="lookups"
          fullWidth={true} formClassName={classes.two_4_input}
          filter={{ type: 'POTMSHTCYCLE'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="poSignatoryName" label="PO Signatory Name " fullWidth={true} validate={[required()]} formClassName={classes.three_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="PO Signatory Designation"
          source="poSignatoryDesignationId" reference="lookups"
          filter={{ type: 'PODESIGNATION'}}
          fullWidth={true} formClassName={classes.last_4_input}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="poSignatoryDate" label="PO Signatory Date " fullWidth={true} formClassName={classes.one_4_input} />
        <TextInput source="poClientSignatoryName" label="PO Client Signatory Name " fullWidth={true} formClassName={classes.two_4_input} />
        <ReferenceInput perPage={25} variant="outlined" label="PO Client Signatory Designation"
          source="poClientSignatoryDesignationId" reference="lookups"
          fullWidth={true} formClassName={classes.three_4_input}
          filter={{ type: 'PODESIGNATION'}}
          validate={[required()]} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="poClientSignatoryDate" label="PO Client Signatory Date " fullWidth={true} formClassName={classes.last_4_input} />
        <DateInput source="contractDate" label="Contract Date " fullWidth={true} formClassName={classes.one_4_input} />
        <TextInput source="workLocation" label="Work Location " fullWidth={true} formClassName={classes.two_4_input} />



        <div>

        </div>
        {<FileInput source="contractUrl1" formClassName={classes.first_inline_input}
          label="Contract" className="logourl">
          <FileField source="contractUrl1" title="Contract" />
        </FileInput>

        }
        <FileField source="contractUrl" label="" title="Download Contract" formClassName={classes.last_inline_input} />

        <div>
        </div>



      </SimpleForm>
    </Create>
  );
};
