import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
export const UsersCreate = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props, "Create");

  let tenantID = localStorage.getItem("TenantID");
  let facilityID = localStorage.getItem("FacilityID");
  let userID = localStorage.getItem("userId");
  let defaultValues = {
    
    isActive: true
  };

  return (
    <Create  {...propsObj}>
      <SimpleForm redirect="list" initialValues={defaultValues} toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        
        <TextInput source="code" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="userName" formClassName={classes.two_three_input} />
        <PasswordInput source="password" formClassName={classes.last_three_input} />
        <TextInput source="name" validate={[required()]} formClassName={classes.one_three_input}   />
        <TextInput source="email" validate={[required()]} formClassName={classes.two_three_input}   />
        <TextInput source="mobileNo" formClassName={classes.last_three_input} />
        <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
          <SelectInput optionText="roleName" validate={[required()]} />
        </ReferenceInput>

        <DateInput source="startDate" formClassName={classes.two_three_input} />
        <DateInput source="endDate" formClassName={classes.last_three_input} />

        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.one_three_input} />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.two_three_input} />
        
        <PasswordInput source="smtpPassword" formClassName={classes.last_three_input} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} formClassName={classes.one_three_input} />
        

      </SimpleForm>
    </Create>
  );
};
